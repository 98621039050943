import { Component, OnInit } from "@angular/core";
import { UpToDateBuildService } from "./up-to-date.service";
import Swal from "sweetalert2";
// import * as CryptoJS from "crypto-js";
import { SharingModule } from "./sharing-module/sharing.module";
// import { environment } from "src/environments/environment";

@Component({
    selector: "app-up-to-date",
    template: "<span></span>",
    standalone: true,
    imports: [SharingModule],
})
export class UpToDateComponent implements OnInit {
    public buildIsUpToDate = true;
    private isPromptShown = false; // Flag to check if prompt has been shown

    constructor(private upToDateService: UpToDateBuildService) {
        this.upToDateService.buildIsUpToDate.subscribe((buildIsUpToDate) => {
            this.buildIsUpToDate = buildIsUpToDate;
            if (!this.buildIsUpToDate && !this.isPromptShown) {
                this.promptReload();
                this.isPromptShown = true; // Set flag to true after prompt is shown
            }
        });
    }

    ngOnInit() {
        console.log("starting on init for build number services");
    }

    private promptReload(): void {
        Swal.fire({
            title: "New Update Available",
            text: "There is a new update! The application will refresh to apply the update.",
            icon: "warning",
            confirmButtonText: "Reload Now",
            customClass: {
                confirmButton: "btn btn-primary confirm-btn me-3",
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showCancelButton: false,
        }).then(() => {
            this.reloadPage();
        });
    }

    private reloadPage(): void {
        // const timestamp = new Date().getTime().toString();
        // const encryptedTimestamp = CryptoJS.AES.encrypt(
        //   timestamp,
        //   environment.secretKey
        // ).toString();

        // const currentUrl = window.location.href;
        // const encryptedNoCacheUrl = currentUrl.includes("?")
        //   ? `${currentUrl}&v=${encodeURIComponent(encryptedTimestamp)}`
        //   : `${currentUrl}?v=${encodeURIComponent(encryptedTimestamp)}`;

        // window.location.href = encryptedNoCacheUrl;
        window.location.reload();
    }
}
