import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { MethodServices } from "./method-services";
import * as CryptoJS from "crypto-js";

interface pathCheck_DataType {
    tipe_check: "path";
    path: any;
}

interface moduleCheck_DataType {
    tipe_check: "module";
    module: any;
}

interface authentical_getFirst_DataType {
    tipe_check: "module";
    name: any;
}

export type paramCheck_DataType = pathCheck_DataType | moduleCheck_DataType;

export let authentical_list: any = [
    { module: "dashboards", path: "/dashboards/hr", authentical: "HR Dashboard" },
    { module: "dashboards", path: "/dashboards/time", authentical: "Time Dashboard" },
    { module: "dashboards", path: "/dashboards/payroll", authentical: "Payroll Dashboard" },
    { module: "dashboards", path: "/dashboards/security", authentical: "Security Dashboard" },
    { module: "dashboards", path: "/dashboards/recruitment", authentical: "Recruitment Dashboard" },
    {
        module: "dashboards",
        path: "/dashboards/extra",
        authentical: ["Benefit Dashboard", "Expense Dashboard", "Loan Dashboard", "Workspace Dashboard", "Asset Dashboard"],
    },
    { module: "dashboards", path: "/dashboards/apris", authentical: "APRIS Dashboard" },

    { module: "customer", path: "/customer/customer", authentical: "SC_ADMIN" },
    { module: "customer", path: "/customer/fail-provisioning", authentical: "SC_ADMIN" },
    { module: "customer", path: "/customer/fail-instance", authentical: "SC_ADMIN" },
    { module: "customer", path: "/customer/server", authentical: "SC_ADMIN" },
    { module: "customer", path: "/customer/maintenance", authentical: "SC_ADMIN" },
    { module: "customer", path: "/customer/batch-update", authentical: "SC_ADMIN" },
    { module: "customer", path: "/customer/third-party", authentical: "SC_ADMIN" },

    { module: "workforce", path: "/workforce/employee", authentical: "" },
    { module: "workforce", path: "/workforce/employee/personal", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/address", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/employment", authentical: "Employment" },
    { module: "workforce", path: "/workforce/employee/assignment", authentical: "Employment" },
    { module: "workforce", path: "/workforce/employee/element-entries", authentical: "Element Entries" },
    { module: "workforce", path: "/workforce/employee/payroll-result", authentical: "Payroll Process" },
    { module: "workforce", path: "/workforce/employee/time-card", authentical: ["Attendance Setup", "Payroll Process"] },
    { module: "workforce", path: "/workforce/employee/project", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/absence", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/calendar", authentical: ["Employee Information", "Payroll Process"] },
    { module: "workforce", path: "/workforce/employee/benefit", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/loan", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/family", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/education", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/certification", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/work-experience", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/document", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/client", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/hierarchy", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/goal", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/checklist", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/questionnaire", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/performance", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/competencies", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/asset", authentical: "Asset Setup" },
    { module: "workforce", path: "/workforce/employee/point-history", authentical: "Point Setup" },
    { module: "workforce", path: "/workforce/employee/medical", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/career-assessment", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/job-qualification", authentical: "Employee Information" },
    { module: "workforce", path: "/workforce/employee/talent-matrix", authentical: "Point Setup" },
    { module: "workforce", path: "/workforce/employee/letter", authentical: "Employee Information" },

    { module: "work-structure", path: "/work-structure/company", authentical: "Work Structure" },
    { module: "work-structure", path: "/work-structure/location-group", authentical: "Work Structure" },
    { module: "work-structure", path: "/work-structure/location", authentical: "Work Structure" },
    { module: "work-structure", path: "/work-structure/grade", authentical: "Work Structure" },
    { module: "work-structure", path: "/work-structure/job-family", authentical: "Work Structure" },
    { module: "work-structure", path: "/work-structure/job", authentical: "Work Structure" },
    { module: "work-structure", path: "/work-structure/organization-level", authentical: "Work Structure" },
    { module: "work-structure", path: "/work-structure/organization", authentical: "Work Structure" },
    { module: "work-structure", path: "/work-structure/position-level", authentical: "Work Structure" },
    { module: "work-structure", path: "/work-structure/position", authentical: "Work Structure" },

    { module: "recruitment", path: "/recruitment/setup/portals", authentical: "Recruitment Setup" },
    { module: "recruitment", path: "/recruitment/setup/client", authentical: "Recruitment Setup" },
    { module: "recruitment", path: "/recruitment/setup/mpp", authentical: "Recruitment Setup" },
    { module: "recruitment", path: "/recruitment/setup/category", authentical: "Recruitment Setup" },
    { module: "recruitment", path: "/recruitment/template/question", authentical: "Recruitment Setup" },
    { module: "recruitment", path: "/recruitment/template/section", authentical: "Recruitment Setup" },
    { module: "recruitment", path: "/recruitment/template/template", authentical: "Recruitment Setup" },
    { module: "recruitment", path: "/recruitment/template/hiring-phase", authentical: "Recruitment Setup" },
    { module: "recruitment", path: "/recruitment/template/hiring-process", authentical: "Recruitment Setup" },
    { module: "recruitment", path: "/recruitment/vacancy/job-requisition", authentical: "Recruitment Setup" },
    { module: "recruitment", path: "/recruitment/vacancy/job-posting", authentical: "Recruitment Setup" },
    { module: "recruitment", path: "/recruitment/applicant-screening/applicant", authentical: "Recruitment Setup" },
    { module: "recruitment", path: "/recruitment/applicant-screening/applicant-selection", authentical: "Recruitment Setup" },

    { module: "workflow-approval", path: "/workflow-approval/eligibility-profiles", authentical: "Workflow Setup" },
    { module: "workflow-approval", path: "/workflow-approval/approval-group", authentical: "Workflow Setup" },
    { module: "workflow-approval", path: "/workflow-approval/workflow", authentical: "Workflow Setup" },

    { module: "time-management", path: "/time-management/holiday", authentical: "Attendance Setup" },
    { module: "time-management", path: "/time-management/shift", authentical: "Attendance Setup" },
    { module: "time-management", path: "/time-management/pattern", authentical: "Attendance Setup" },
    { module: "time-management", path: "/time-management/attendance-group", authentical: "Attendance Setup" },

    { module: "absence", path: "/absence/eligibility-profiles", authentical: "Absence Setup" },
    { module: "absence", path: "/absence/absence-type", authentical: "Absence Setup" },
    { module: "absence", path: "/absence/adjust-balance", authentical: "Absence Setup" },

    { module: "benefit", path: "/benefit/eligibility-profiles", authentical: "Benefit Setup" },
    { module: "benefit", path: "/benefit/benefit-type", authentical: "Benefit Setup" },
    { module: "benefit", path: "/benefit/adjust-balance", authentical: "Benefit Setup" },

    { module: "expense", path: "/expense/currencies", authentical: "Expense Setup" },
    { module: "expense", path: "/expense/exchange-rates", authentical: "Expense Setup" },
    { module: "expense", path: "/expense/expense-type", authentical: "Expense Setup" },
    { module: "expense", path: "/expense/project", authentical: "Expense Setup" },

    { module: "business-trip", path: "/business-trip/cities", authentical: "Expense Setup" },
    { module: "business-trip", path: "/business-trip/categories", authentical: "Expense Setup" },

    { module: "loan", path: "/loan/eligibility-profiles", authentical: "Loan" },
    { module: "loan", path: "/loan/loan-type", authentical: "Loan" },

    { module: "asset", path: "/asset/eligibility-profiles", authentical: "Asset Setup" },
    { module: "asset", path: "/asset/asset-type", authentical: "Asset Setup" },

    { module: "workspace", path: "/workspace/eligibility-profiles", authentical: "Workspace Setup" },
    { module: "workspace", path: "/workspace/workspace-type", authentical: "Workspace Setup" },

    { module: "transaction", path: "/transaction/personal/biodata", authentical: "Employee Information" },
    { module: "transaction", path: "/transaction/personal/address", authentical: "Employee Information" },
    { module: "transaction", path: "/transaction/personal/family", authentical: "Employee Information" },
    { module: "transaction", path: "/transaction/personal/education", authentical: "Employee Information" },
    { module: "transaction", path: "/transaction/personal/work-experiences", authentical: "Employee Information" },
    { module: "transaction", path: "/transaction/personal/certification", authentical: "Employee Information" },
    { module: "transaction", path: "/transaction/personal/document", authentical: "Employee Information" },
    { module: "transaction", path: "/transaction/time/checkinout", authentical: "Attendance Setup" },
    { module: "transaction", path: "/transaction/time/overtime", authentical: "Attendance Setup" },
    { module: "transaction", path: "/transaction/time/time-off", authentical: "Attendance Setup" },
    { module: "transaction", path: "/transaction/time/shift-change", authentical: "Attendance Setup" },
    { module: "transaction", path: "/transaction/time/absence", authentical: "Absence Setup" },
    { module: "transaction", path: "/transaction/talent/goal", authentical: "Goal Setup" },
    { module: "transaction", path: "/transaction/talent/career-evaluation", title: "Career Evaluation", authentical: "Career Assessment Evaluator" },
    { module: "transaction", path: "/transaction/extra/benefit", authentical: "Benefit Setup" },
    { module: "transaction", path: "/transaction/extra/expense", authentical: "Expense Setup" },
    { module: "transaction", path: "/transaction/extra/loan", authentical: "Loan" },
    { module: "transaction", path: "/transaction/extra/workspace", authentical: "Workspace Setup" },
    { module: "transaction", path: "/transaction/extra/starpoint", authentical: "Point Setup" },
    { module: "transaction", path: "/transaction/extra/business-trip", title: "Business Trip", authentical: "Expense Setup" },

    { module: "goal", path: "/goal/eligibility-profiles", authentical: "Goal Setup" },
    { module: "goal", path: "/goal/goal-library", authentical: "Goal Setup" },
    { module: "goal", path: "/goal/goal-plan", authentical: "Goal Setup" },

    { module: "performance", path: "/performance/eligibility-profiles", authentical: "Performance Setup" },
    { module: "performance", path: "/performance/question", authentical: "Performance Setup" },
    { module: "performance", path: "/performance/item-category", authentical: "Performance Setup" },
    { module: "performance", path: "/performance/item", authentical: "Performance Setup" },
    { module: "performance", path: "/performance/section", authentical: "Performance Setup" },
    { module: "performance", path: "/performance/template", authentical: "Performance Setup" },
    { module: "performance", path: "/performance/manage-evaluation", authentical: "Performance Setup" },
    { module: "performance", path: "/performance/calibration", authentical: "Performance Setup" },
    { module: "performance", path: "/performance/talent-matrix", authentical: "Performance Setup" },

    { module: "checklist", path: "/checklist/eligibility-profiles", authentical: "Checklist Setup" },
    { module: "checklist", path: "/checklist/checklist-template", authentical: "Checklist Setup" },

    { module: "questionnaire", path: "/questionnaire/eligibility-profiles", authentical: "Questionnaire Setup" },
    { module: "questionnaire", path: "/questionnaire/question", authentical: "Questionnaire Setup" },
    { module: "questionnaire", path: "/questionnaire/section", authentical: "Questionnaire Setup" },
    { module: "questionnaire", path: "/questionnaire/template", authentical: "Questionnaire Setup" },

    { module: "voice-of-employee", path: "/voice-of-employee/incident", authentical: "Voice of Employee Admin" },
    { module: "voice-of-employee", path: "/voice-of-employee/category", authentical: "Voice of Employee Admin" },

    { module: "payroll", path: "/payroll/transaction/process-result", authentical: "Payroll Process" },
    { module: "payroll", path: "/payroll/transaction/costing-payroll", authentical: "Payroll Process" },
    { module: "payroll", path: "/payroll/transaction/closing-payroll", authentical: "Payroll Process" },
    { module: "payroll", path: "/payroll/transaction/tax-process", authentical: "Payroll Process" },
    { module: "payroll", path: "/payroll/transaction/compensation-change", authentical: "Payroll Process" },
    { module: "payroll", path: "/payroll/setup/chart-of-accounts", authentical: "Payroll Setup" },
    { module: "payroll", path: "/payroll/setup/tax-bracket", authentical: "Payroll Setup" },
    { module: "payroll", path: "/payroll/setup/payroll-group", authentical: "Payroll Setup" },
    { module: "payroll", path: "/payroll/setup/element-classification", authentical: "Payroll Setup" },
    { module: "payroll", path: "/payroll/setup/element", authentical: "Payroll Setup" },
    { module: "payroll", path: "/payroll/setup/balance", authentical: "Payroll Setup" },
    { module: "payroll", path: "/payroll/setup/runtype", authentical: "Payroll Setup" },

    { module: "security", path: "/security/eligibility-profiles", authentical: "Security Setup" },
    { module: "security", path: "/security/roles", authentical: "Security Setup" },
    { module: "security", path: "/security/users", authentical: "Security Setup" },

    { module: "tools", path: "/tools/approval-delegation", authentical: "Approval Delegation" },
    { module: "tools", path: "/tools/process-manager", authentical: null },
    { module: "tools", path: "/tools/import-data", authentical: "Import" },
    { module: "tools", path: "/tools/mass-update", authentical: "Mass Updates" },
    { module: "tools", path: "/tools/global-values", authentical: "Global Value Setup" },
    { module: "tools", path: "/tools/auto-number", authentical: "Global Value Setup" },
    { module: "tools", path: "/tools/news-feed", authentical: "News Feed Setup" },
    { module: "tools", path: "/tools/quotes", authentical: "Global Value Setup" },
    { module: "tools", path: "/tools/mobile-menu", authentical: "Mobile Setup" },

    { module: "template", path: "/template/greetings-template", authentical: "Greeting Template Setup" },
    { module: "template", path: "/template/letter-template", authentical: "Letter Setup" },

    { module: "license", path: "/license", authentical: null },

    // {module:"my-team", path:"/my-team/my-teams", authentical: "My Team"},
    // {module:"my-team", path:"/my-team/career-evaluation", authentical: "Career Assessment Evaluator"},
    { module: "my-team", path: "/my-team/report-manager", authentical: "My Team" },

    { module: "career", path: "/career/question", authentical: "Performance Setup" },
    { module: "career", path: "/career/item", authentical: "Performance Setup" },
    { module: "career", path: "/career/template", authentical: "Performance Setup" },
    { module: "career", path: "/career/assessment-process", authentical: "Performance Setup" },
    { module: "career", path: "/career/manage-assessment", authentical: "Performance Setup" },

    { module: "competency", path: "/competency/competency-category", authentical: "Performance Setup" },
    { module: "competency", path: "/competency/competencies", authentical: "Performance Setup" },
    { module: "competency", path: "/competency/rating-model", authentical: "Performance Setup" },

    { module: "membership", path: "/membership/level-field", authentical: "Performance Setup" },

    { module: "starfeeds", path: "/starfeeds/category", authentical: "Starfeeds Setup" },
    { module: "starfeeds", path: "/starfeeds/feeds", authentical: "Starfeeds Setup" },
    { module: "starfeeds", path: "/starfeeds/starfeeds", authentical: "Starfeeds Setup" },

    { module: "starpoint", path: "/starpoint/catalog", authentical: "Point Setup" },
    { module: "starpoint", path: "/starpoint/level", authentical: "Point Setup" },
    { module: "starpoint", path: "/starpoint/manage-point", authentical: "Point Setup" },

    { module: "ai", path: "/ai/knowledge", authentical: "AI Setup" },
];

@Injectable({ providedIn: "root" })
export class CanActivateGuardServices {
    constructor(private methodServices: MethodServices) {}

    authentical_get_first_path(tipeCheck: authentical_getFirst_DataType) {
        let jwtService = new JwtHelperService();

        if (typeof localStorage.getItem("GITAUTHTKN") != "undefined" && localStorage.getItem("GITAUTHTKN") != null) {
            let authoritiesToken: any = jwtService.decodeToken(this.methodServices.ivSha256Decrypt(localStorage.getItem("GITAUTHTKN")))?.["authorities"];

            let authentical_list_final: any[] = authentical_list.filter((row) => {
                if (row?.["module"] == tipeCheck?.["name"]) {
                    let _find: any = authoritiesToken.find((row_authToken) => row_authToken == row?.["authentical"]);
                    if (_find) {
                        return true;
                    }
                }
            });

            if (authentical_list_final.length > 0) {
                return authentical_list_final[0]?.["path"];
            }
        }
    }

    route_pathFromRoot(pathFromRoot: any): any {
        let arrTemp: any[] = [];

        // AMBIL PATH yang ada guard nya dari route.pathFromRoot

        pathFromRoot.forEach((path: any) => {
            if (path?.["routeConfig"]?.["path"] != null && path?.["routeConfig"]?.["path"] != "" && typeof path?.["routeConfig"]?.["path"] != "undefined") {
                arrTemp.push(path?.["routeConfig"]?.["path"]);
            }
        });

        let arrTemp_join: any = "/" + arrTemp.join("/").toString();
        return arrTemp_join;
    }

    check_authentical(obj: paramCheck_DataType) {
        let authToken_raw: any;
        let authToken_decode: any[] = [];
        let authentical: any;

        let jwtService = new JwtHelperService();

        if (localStorage.length > 0) {
            // get roles "SUPER_ADMIN, SC_ADMIN, or STAFF" from keyjson (biasa untuk employee)
            for (let idx = 0; idx < localStorage.length; idx++) {
                let key = localStorage.key(idx);
                try {
                    let keyName = this.methodServices.ivSha256Decrypt(key);
                    if (keyName == "keyjson") {
                        let accessTokenDecode = jwtService.decodeToken(JSON.parse(this.methodServices.ivSha256Decrypt(localStorage.getItem(localStorage.key(idx)))).access_token);
                        if (typeof accessTokenDecode.resource_access["git-client"] != "undefined") {
                            let getCheckDataAdmin = accessTokenDecode.resource_access["git-client"]["roles"].findIndex((result) => result == "SUPER_ADMIN");
                            let getCheckDataSCAdmin = accessTokenDecode.resource_access["git-client"]["roles"].findIndex((result) => result == "SC_ADMIN");
                            if (getCheckDataAdmin != -1 && getCheckDataSCAdmin == -1) {
                                this.methodServices.jwt_role_user = accessTokenDecode.resource_access["git-client"]["roles"][getCheckDataAdmin];
                            } else if (getCheckDataSCAdmin != -1 && getCheckDataAdmin == -1) {
                                this.methodServices.jwt_role_user = accessTokenDecode.resource_access["git-client"]["roles"][getCheckDataSCAdmin];
                            } else {
                                this.methodServices.jwt_role_user = "STAFF";
                            }
                        }
                    }
                } catch (err) {}
            }

            // ... end get roles

            // (KONDISI KHUSUS) check SUPER_ADMIN, SC_ADMIN dimana ada beberapa module yang boleh langsung masuk

            let path_temp: any = "";
            switch (obj?.["tipe_check"]) {
                case "path":
                    path_temp = obj?.["path"];
                    break;
                case "module":
                    path_temp = obj?.["module"];
                    break;
            }

            let module_temp: any = "";

            if (typeof path_temp != "undefined" && path_temp != "" && path_temp != null) {
                let arr_temp: any = path_temp.split("/");
                arr_temp = arr_temp.filter(Boolean); // remove yang kosong, null, undefined
                if (arr_temp.length > 0) {
                    module_temp = arr_temp[0]; // ambil indeks pertama sebagai indikator module pertama (e.g. employee, license, customer)
                }
            }

            if (typeof this.methodServices.jwt_role_user != "undefined" && this.methodServices.jwt_role_user != null && this.methodServices.jwt_role_user != "") {
                if (typeof module_temp != "undefined" && module_temp != null && module_temp != "") {
                    if (this.methodServices.jwt_role_user == "SUPER_ADMIN") {
                        if (module_temp == "workforce" || module_temp == "license" || module_temp == "security") {
                            return true;
                        }

                        // module customer hanya untuk SC_ADMIN
                        if (module_temp == "customer") {
                            return false;
                        }
                    } else if (this.methodServices.jwt_role_user == "SC_ADMIN") {
                        if (module_temp == "customer") {
                            return true;
                        } else {
                            // module 'license' hanya untuk SUPER_ADMIN
                            if (module_temp == "license") {
                                return false;
                            }
                        }
                    } else {
                        if (module_temp == "license") {
                            return false;
                        }
                        if (module_temp == "customer") {
                            return false;
                        }
                    }
                }
            } else {
                // jika bukan "SUPER_ADMIN", maka tidak diizinkan masuk ke license
                if (module_temp == "license") {
                    return false;
                }
                // jika bukan "SC_ADMIN", maka tidak diizinkan masuk ke customer
                if (module_temp == "customer") {
                    return false;
                }
            }
            // ... end (KONDISI KHUSUS) check SUPER_ADMIN, SC_ADMIN

            if (typeof localStorage.getItem("GITAUTHTKN") == "undefined" || localStorage.getItem("GITAUTHTKN") == null) {
                return false;
            }

            try {
                authToken_raw = this.methodServices.ivSha256Decrypt(localStorage.getItem("GITAUTHTKN"));
            } catch (e) {
                return false;
            }

            // MASTER AUTHENTICAL DARI LOCALSTORAGE

            authToken_decode = jwtService.decodeToken(authToken_raw)?.["authorities"];
            // ... end
            if (obj?.["tipe_check"] == "path") {
                // KONDISI KHUSUS "personal" langsung boleh masuk jikalau role_user == 'SUPER_ADMIN'
                if (obj?.["path"] == "/workforce/employee/personal" && typeof this.methodServices.jwt_role_user != "undefined" && this.methodServices.jwt_role_user == "SUPER_ADMIN") {
                    return true;
                }
                // ... end kondisi khusus

                // authentical pada lokal component
                let _find = authentical_list.find((res) => res?.["path"] == obj?.["path"]);
                if (_find) {
                    // kondisi "undefined"
                    if (typeof _find?.["authentical"] == "undefined") {
                        return true;
                    }

                    // kondisi "null"
                    if (_find?.["authentical"] == null || _find?.["authentical"] == "") {
                        return true;
                    }

                    // kondisi "string"
                    if (typeof _find?.["authentical"] == "string") {
                        authentical = _find?.["authentical"];

                        // compare apakah ada di localstorage
                        let _find_localstorage = authToken_decode.find((res) => res === authentical);
                        if (_find_localstorage) {
                            return true;
                        } else {
                            return false;
                        }
                    }

                    // kondisi "array"
                    if (Array.isArray(_find?.["authentical"])) {
                        let arrTemp = _find?.["authentical"];

                        let _find_localstorage = arrTemp.some((res) => authToken_decode.indexOf(res) != -1);
                        return _find_localstorage;
                    }
                } else {
                    return false;
                }
            } else if (obj?.["tipe_check"] == "module") {
                // follow later
                let _arrFilter: any[] = JSON.parse(JSON.stringify(authentical_list.filter((res) => res?.["module"] == obj?.["module"])));

                let _find = _arrFilter.some((res) => {
                    // kondisi 'undefined' or 'null'
                    if (typeof res?.["authentical"] == "undefined" || res?.["authentical"] == null) {
                        return true;
                    }

                    // kondisi "array"
                    if (Array.isArray(res?.["authentical"])) {
                        let arrTemp = res?.["authentical"];

                        let _find_localstorage = arrTemp.some((res_inarr) => authToken_decode.indexOf(res_inarr) != -1);
                        return _find_localstorage;
                    }

                    // kondisi "string"
                    if (typeof res?.["authentical"] == "string") {
                        return authToken_decode.indexOf(res?.["authentical"]) != -1;
                    }
                });
                return _find;
            }
        } else {
            return false;
        }
    }

    // GENERATE JWT

    encodeToken(payload: any) {
        var header = {
            alg: "HS256",
            typ: "JWT",
        };

        var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
        var encodedHeader = this.base64url(stringifiedHeader);

        var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
        var encodedData = this.base64url(stringifiedData);

        var token = encodedHeader + "." + encodedData;

        return token;
    }

    base64url(source: any) {
        let encodedSource = CryptoJS.enc.Base64.stringify(source);

        encodedSource = encodedSource.replace(/=+$/, "");

        encodedSource = encodedSource.replace(/\+/g, "-");
        encodedSource = encodedSource.replace(/\//g, "_");

        return encodedSource;
    }
}
