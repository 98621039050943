import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from "@angular/router";
import { MethodServices } from "../services/method-services";
import { DataTypeServices } from "src/services/data-type-services";
import { TranslateService } from "@ngx-translate/core";
import { setTheme } from "ngx-bootstrap/utils";
import { Subject, takeUntil } from "rxjs";
import { JwtHelperService } from "@auth0/angular-jwt";
import { SharingModule } from "./sharing-module/sharing.module";
import { UpToDateComponent } from "./up-to-date.component";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    standalone: true,
    imports: [SharingModule, UpToDateComponent],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    statusDark: boolean = false;

    ngUnsubscribe: Subject<any> = new Subject();

    autoLogin: boolean = false;

    needRedirectHome: boolean = false;
    ignoreRedirect: boolean = false;

    currentDate: Date = new Date();
    clockTicking: any;

    constructor(private router: Router, public dataTypeServices: DataTypeServices, private methodServices: MethodServices, private translate: TranslateService) {
        setTheme("bs5");

        // Temporary Fixing
        // Add first validation before getInfoStorage in OnInit
        if (window.location.pathname == "/" || window.location.pathname == "/login") this.needRedirectHome = true;

        if (window.location.pathname == "/register-demo" || window.location.pathname == "/finishpayment" || window.location.pathname == "/payment-register") this.ignoreRedirect = true;

        this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: Event) => {
            //  router events untuk detect jika ada perubahan path url
            if (event instanceof NavigationStart) {
                if (this.currentDate.getHours() < 12) {
                    this.methodServices.layoutStyleTime = "MORNING";
                } else if (this.currentDate.getHours() >= 12 && this.currentDate.getHours() <= 17) {
                    this.methodServices.layoutStyleTime = "AFTERNOON";
                } else {
                    this.methodServices.layoutStyleTime = "NIGHT";
                }
                // SET DARK MODE
                if (localStorage.getItem("GITDMOD") == null || typeof localStorage.getItem("GITDMOD") == "undefined") {
                    localStorage.setItem("GITDMOD", this.methodServices.ivSha256Encrypt("false"));
                }

                if (typeof localStorage.getItem("GITLANG") == "undefined") {
                    localStorage.setItem("GITLANG", this.methodServices.ivSha256Encrypt("en"));
                }
                // Show loading indicator
                window.scrollTo(0, 0);
                // CEK DARK MODE
            }

            if (event instanceof NavigationEnd) {
                setTimeout(() => {
                    if (event.url == "/" || event.url == "/login") this.needRedirectHome = true;

                    if (event.url == "/register-demo" || event.url == "/finishpayment" || event.url == "/payment-register") this.ignoreRedirect = true;

                    if (localStorage.getItem("GITDMOD") != null && typeof localStorage.getItem("GITDMOD") != "undefined") {
                        if (this.methodServices.ivSha256Decrypt(localStorage.getItem("GITDMOD").toString()) == "true") {
                            this.methodServices.subject_DarkMode.next(true);
                            this.statusDark = true;
                            this.methodServices.statusDark = true;
                        } else {
                            this.methodServices.subject_DarkMode.next(false);
                            this.statusDark = false;
                            this.methodServices.statusDark = false;
                        }
                    }
                    this.methodServices.openTopNav.next(false);
                });

                // Hide loading indicator
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                console.error(event);
            }
        });

        translate.setDefaultLang("en");

        translate.use("en");

        //  CALL DARK MODE FUNCTION
        this.funcDarkMode();

        // Remove all service worker files in cache storage
        this.clearServiceWorkerCache();
    }

    ngOnInit() {
        
        this.getInfoStorage();

        //Call currentDate first time when app refreshed
        this.currentDate = new Date();

        if (typeof localStorage.getItem("GITAOD") != "undefined" && localStorage.getItem("GITAOD") != null) {
            localStorage.removeItem("GITAOD");
        }

        localStorage.setItem("GITAOD", this.methodServices.ivSha256Encrypt(this.methodServices.formatDate(this.currentDate, "yyyy-mm-dd")));

        // Update currentDate every 30 minutes
        this.clockTicking = setInterval(() => {
            this.currentDate = new Date();
        }, 1800000);
    }

    ngAfterViewInit(): void {
        //  this.applyTheme('dark')
    }

    getInfoStorage() {
        let existsToken: boolean = false;
        const helper = new JwtHelperService();

        if (typeof this.methodServices.jwt_access_token == undefined || this.methodServices.jwt_access_token == null || this.methodServices.jwt_access_token == "" || typeof this.methodServices.jwt_access_token == "undefined") {
            if (localStorage.length > 0) {
                for (var i = 0; i < localStorage.length; i++) {
                    try {
                        if (localStorage.key(i) != "GITAOD") {
                            if (typeof localStorage.getItem("GITAUTHTKN") != "undefined" && localStorage.getItem("GITAUTHTKN") != null) {
                                this.methodServices.jwt_authorities_value = this.methodServices.ivSha256Decrypt(localStorage.getItem("GITAUTHTKN"));
                            }

                            if (typeof localStorage.getItem("GITINSTAPI") != "undefined" && localStorage.getItem("GITINSTAPI") != null) {
                                this.methodServices.jwt_instance_api = this.methodServices.ivSha256Decrypt(localStorage.getItem("GITINSTAPI"));
                            }
                            if (this.methodServices.ivSha256Decrypt(localStorage.key(i)) != "") {
                                // return
                                let keyStor = this.methodServices.ivSha256Decrypt(localStorage.key(i));
                                let valStor = this.methodServices.ivSha256Decrypt(localStorage.getItem(localStorage.key(i)));

                                if (keyStor == "keyjson") {
                                    this.methodServices.jwt_keyjson = valStor;
                                    let valStorObj = JSON.parse(valStor);
                                    this.methodServices.jwt_access_token = typeof valStorObj["access_token"] != "undefined" ? valStorObj["access_token"] : null;
                                    this.methodServices.jwt_refresh_token = typeof valStorObj["refresh_token"] != "undefined" ? valStorObj["refresh_token"] : null;

                                    let jwt = new JwtHelperService();
                                    let jwtDecode = jwt.decodeToken(this.methodServices.jwt_access_token);
                                    this.methodServices.jwt_instance_api = jwtDecode["instance_api"][0];
                                    this.methodServices.jwt_tenant_id = jwtDecode["tenant_id"][0];
                                    existsToken = true;
                                }
                                if (keyStor == "authorities") {
                                    this.methodServices.jwt_authorities_key = localStorage.key(i);
                                    this.methodServices.jwt_authorities = JSON.parse(valStor);
                                }

                                if (keyStor == "keyjson") {
                                    let accessTokenDecode = helper.decodeToken(JSON.parse(this.methodServices.ivSha256Decrypt(localStorage.getItem(localStorage.key(i)))).access_token);
                                    if (typeof accessTokenDecode["resource_access"]["git-client"] != "undefined") {
                                        let getCheckData_SUPER_Admin = accessTokenDecode["resource_access"]["git-client"]["roles"].findIndex((result) => result == "SUPER_ADMIN");
                                        let getCheckData_SC_Admin = accessTokenDecode["resource_access"]["git-client"]["roles"].findIndex((result) => result == "SC_ADMIN");
                                        if (getCheckData_SUPER_Admin != -1 && getCheckData_SC_Admin == -1) {
                                            this.methodServices.jwt_role_user = accessTokenDecode["resource_access"]["git-client"]["roles"][getCheckData_SUPER_Admin];
                                        } else if (getCheckData_SC_Admin != -1 && getCheckData_SUPER_Admin == -1) {
                                            this.methodServices.jwt_role_user = accessTokenDecode["resource_access"]["git-client"]["roles"][getCheckData_SC_Admin];
                                        } else {
                                            this.methodServices.jwt_role_user = "STAFF";
                                        }
                                    } else {
                                        this.methodServices.jwt_role_user = "STAFF";
                                    }

                                    // SET GITROLE to localStorage
                                    if (typeof localStorage.getItem("GITROLE") != "undefined" && localStorage.getItem("GITROLE") != null) {
                                        localStorage.removeItem("GITROLE");
                                    }
                                    localStorage.setItem("GITROLE", this.methodServices.ivSha256Encrypt(this.methodServices.jwt_role_user));

                                    this.autoLogin = true;
                                }
                            }
                        }
                    } catch (err) {
                        console.log("error get api : " + err);
                    }
                }
                setTimeout(() => {
                    if (this.autoLogin && this.needRedirectHome) {
                        this.router.navigate(["home/profile"]);
                    } else if (!this.autoLogin && !this.ignoreRedirect) {
                        this.router.navigate(["login"]);
                    } else {
                        if (!this.autoLogin && !this.ignoreRedirect) {
                            this.router.navigate(["login"]);
                        }
                    }
                }, 100);
            } else {
                this.router.navigate(["login"]);
            }
        } else {
            this.router.navigate(["login"]);
        }
    }
    funcDarkMode() {
        //  DARK MODE SUBSCRIBE (dari navbar)
        this.methodServices.subject_DarkMode.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result: any) => {
            if (localStorage.getItem("GITDMOD") == null || typeof localStorage.getItem("GITDMOD") == "undefined") {
                localStorage.setItem("GITDMOD", this.methodServices.ivSha256Encrypt(result.toString()));
            }

            this.statusDark = result;

            var body = document.getElementsByTagName("body")[0];
            if (body.classList.contains("bg-secondary") && this.router.url != "/login") {
                body.classList.remove("bg-secondary");
            }

            if (body.classList.contains("darkMode") && this.router.url == "/login") {
                body.classList.remove("darkMode");
            }

            document.body.setAttribute("data-theme", result ? "dark" : "light");
        });
    }
    ngOnDestroy(): void {
        this.ngUnsubscribe.next(void 0);
        this.ngUnsubscribe.complete();
        clearInterval(this.clockTicking);
    }

    clearServiceWorkerCache() {
        // Unregister all service workers
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.getRegistrations().then(registrations => {
            for (let registration of registrations) {
              registration.unregister();
            }
          });
        }
    
        // Clear all caches
        if ('caches' in window) {
          caches.keys().then(cacheNames => {
            cacheNames.forEach(cacheName => {
              caches.delete(cacheName);
            });
          });
        }
      }
}
