import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { CommonModule, DecimalPipe, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { BrowserModule, provideClientHydration } from "@angular/platform-browser";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { MaintenanceComponent } from "./maintenance/maintenance.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { BuildDetailsHttpService } from "./build-details/build-details.http.service";
import { GlobalErrorHandler } from "src/services/global-handle-error";
import { LoginComponent } from "./layouts/auth-layout/login/login.component";
import { BlankPageComponent } from "./blankpage/blankpage.component";
import { RegisterDemoComponent } from "./layouts/auth-layout/register-demo/register-demo.component";
import { PaymentRegisterComponent } from "./layouts/auth-layout/payment-register/payment-register.component";
import { FinishpaymentComponent } from "./layouts/auth-layout/finishpayment/finishpayment.component";

export const routes: Routes = [
    {
        path: "",
        component: AuthLayoutComponent,
        children: [
            {
                path: "",
                loadChildren: () => import("./layouts/auth-layout/auth-layout.module").then((m) => m.AuthLayoutModule),
            },
        ],
    },
    {
        path: "",
        component: AdminLayoutComponent,
        children: [
            {
                path: "dashboards",
                loadChildren: () => import("./pages/dashboards/dashboards.routing").then((m) => m.DashboardsRoutes),
            },
            {
                path: "home",
                loadChildren: () => import("./pages/home/home.routing").then((m) => m.HomeRoutes),
            },
            {
                path: "client",
                loadChildren: () => import("./pages/client/client.routing").then((m) => m.ClientRoutes),
            },
            {
                path: "workforce",
                loadChildren: () => import("./pages/employee/employee.routing").then((m) => m.EmployeeRoutes),
            },
            {
                path: "work-structure",
                loadChildren: () => import("./pages/work-structure/work-structure.routing").then((m) => m.WorkStructureRoutes),
            },
            {
                path: "recruitment",
                loadChildren: () => import("./pages/recruitment/recruitment.routing").then((m) => m.RecruitmentRoutes),
            },
            {
                path: "license",
                loadChildren: () => import("./pages/license/license.routing").then((m) => m.LicenseRoutes),
            },
            {
                path: "customer",
                loadChildren: () => import("./pages/customer/customer.routing").then((m) => m.CustomerRouting),
            },
            {
                path: "expense",
                loadChildren: () => import("./pages/expense/expense.routing").then((m) => m.ExpenseRoutes),
            },
            {
                path: "security",
                loadChildren: () => import("./pages/security/security.routing").then((m) => m.SecurityRoutes),
            },
            {
                path: "tools",
                loadChildren: () => import("./pages/tools/tools.routing").then((m) => m.ToolsRoutes),
            },
            {
                path: "payroll",
                loadChildren: () => import("./pages/payroll/payroll.routing").then((m) => m.PayrollRoutes),
            },
            {
                path: "competency",
                loadChildren: () => import("./pages/competency/competency.routing").then((m) => m.CompetencyRoutes),
            },
            {
                path: "performance",
                loadChildren: () => import("./pages/performance/performance.routing").then((m) => m.PerformanceRoutes),
            },
            {
                path: "time-management",
                loadChildren: () => import("./pages/time-management/time-management.routing").then((m) => m.TimeManagementRoutes),
            },
            {
                path: "absence",
                loadChildren: () => import("./pages/absence/absence.routing").then((m) => m.AbsenceRoutes),
            },
            {
                path: "benefit",
                loadChildren: () => import("./pages/benefit/benefit.routing").then((m) => m.BenefitRoutes),
            },
            {
                path: "workflow-approval",
                loadChildren: () => import("./pages/workflow-approval/workflow-approval.routing").then((m) => m.WorkflowApprovalRoutes),
            },
            {
                path: "goal",
                loadChildren: () => import("./pages/goal/goal.routing").then((m) => m.GoalRoutes),
            },
            {
                path: "checklist",
                loadChildren: () => import("./pages/checklist/checklist.routing").then((m) => m.ChecklistRoutes),
            },
            {
                path: "workspace",
                loadChildren: () => import("./pages/workspace/workspace.routing").then((m) => m.WorkspaceRoutes),
            },
            {
                path: "voice-of-employee",
                loadChildren: () => import("./pages/voice-of-employee/voice-of-employee.routing").then((m) => m.VoiceOfEmployeeRoutes),
            },
            {
                path: "transaction",
                loadChildren: () => import("./pages/transaction/transaction.routing").then((m) => m.TransactionRoutes),
            },
            {
                path: "questionnaire",
                loadChildren: () => import("./pages/questionnaire/questionnaire.routing").then((m) => m.QuetionnaireRoutes),
            },
            {
                path: "asset",
                loadChildren: () => import("./pages/asset/asset.routing").then((m) => m.AssetRoutes),
            },
            {
                path: "loan",
                loadChildren: () => import("./pages/loan/loan.routing").then((m) => m.LoanRoutes),
            },
            {
                path: "learning",
                loadChildren: () => import("./pages/learning/learning.routing").then((m) => m.LearningRoutes),
            },
            {
                path: "my-team",
                loadChildren: () => import("./pages/my-team/my-team.routing").then((m) => m.MyTeamRoutes),
            },
            {
                path: "career",
                loadChildren: () => import("./pages/career/career.routing").then((m) => m.CareerRoutes),
            },
            {
                path: "membership",
                loadChildren: () => import("./pages/membership/membership.routing").then((m) => m.MembershipRoutes),
            },
            {
                path: "starfeeds",
                loadChildren: () => import("./pages/starfeed/starfeed.routing").then((m) => m.StarfeedRoutes),
            },
            {
                path: "business-trip",
                loadChildren: () => import("./pages/business-trip/business-trip.routing").then((m) => m.BusinessTripRoutes),
            },
            {
                path: "starpoint",
                loadChildren: () => import("./pages/point/point.routing").then((m) => m.PointRoutes),
            },
            {
                path: "template",
                loadChildren: () => import("./pages/template/template.routing").then((m) => m.TemplateRoutes),
            },
            {
                path: "ai",
                loadChildren: () => import("./pages/ai/ai.routing").then((m) => m.AiRoutes),
            },
        ],
    },
    {
        path: "login",
        component: LoginComponent,
    },
    {
        path: "maintenance",
        component: MaintenanceComponent,
    },
    {
        path: "blankpage",
        component: BlankPageComponent,
    },
    {
        path: "register-demo",
        component: RegisterDemoComponent,
    },
    {
        path: "payment-register",
        component: PaymentRegisterComponent,
    },
    {
        path: "finishpayment",
        component: FinishpaymentComponent,
    },
];

// for translation file i18n
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function fetchBuildDetails(buildDetailsService: BuildDetailsHttpService) {
    return () => buildDetailsService.fetchBuildDetails();
}

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        HttpClientModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            defaultLanguage: "en",
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            useHash: false,
        }),
    ],
    providers: [DecimalPipe, { provide: ErrorHandler, useClass: GlobalErrorHandler }, { provide: LocationStrategy, useClass: PathLocationStrategy }, { provide: APP_INITIALIZER, useFactory: fetchBuildDetails, deps: [BuildDetailsHttpService], multi: true }],
    exports: [RouterModule],
})
export class AppRoutingModule {}
