import { enableProdMode, importProvidersFrom, isDevMode, provideZoneChangeDetection } from "@angular/core";

import { environment } from "./environments/environment";
import { bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "./app/app.component";
import { AppRoutingModule } from "./app/app-routing.module";
import { provideHttpClient } from "@angular/common/http";
import { IConfig, provideEnvironmentNgxMask } from "ngx-mask";
import { SharingModule } from "./app/sharing-module/sharing.module";

const maskConfig: Partial<IConfig> = {
  validation: false,
};

if (environment.production) {
  enableProdMode();
  if(window){
    window.console.log=function(){};
  }
}
else{
  // OTHER
  if(window){
    // window.console.log=function(){};
  }
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(AppRoutingModule, SharingModule),
    provideHttpClient(),
    provideEnvironmentNgxMask(maskConfig),
    provideZoneChangeDetection({ eventCoalescing: true }),
]
}).catch(err => console.error(err));