import { Component, OnInit, EventEmitter, OnDestroy, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { SharingModule } from "src/app/sharing-module/sharing.module";
import { CanActivateGuardServices } from "src/services/canActivate-guard-services";
import { MethodServices } from "src/services/method-services";

export const subject_sidebar_userprofile_status: Subject<boolean> = new Subject<boolean>();

export interface RouteInfo {
    path: string;
    title: string;
    categoryId: string;
    type: string;
    icontype: string;
    authentical: string;
    collapse?: string;
    isCollapsed?: boolean;
    isCollapsing?: any;
    children?: ChildrenItems[];
    isImage?: boolean;
    imgSrc?: string;
}

export interface ChildrenItems {
    parentPath?: string; //only for quick access menu
    path: string;
    title: string;
    type?: string;
    collapse?: string;
    children?: ChildrenItems2[];
    isCollapsed?: boolean;
    authentical: string;
}

export interface ChildrenItems2 {
    path?: string;
    title?: string;
    type?: string;
}

export interface CategoriesMenu {
    categoryId: string;
    categoryName: string;
    iconType: string;
}

export const categoriesMenus: CategoriesMenu[] = [
    {
        categoryId: "dashboard",
        categoryName: "Home",
        iconType: "dashboard",
    },
    {
        categoryId: "insight",
        categoryName: "Insight",
        iconType: "insight",
    },
    {
        categoryId: "people-structure",
        categoryName: "People & Structure",
        iconType: "people-structure",
    },
    {
        categoryId: "recruitments",
        categoryName: "Recruitments",
        iconType: "recruitments",
    },
    {
        categoryId: "time-absence",
        categoryName: "Time & Absence",
        iconType: "time-absence",
    },
    {
        categoryId: "facilities",
        categoryName: "Facilities",
        iconType: "facilities",
    },
    {
        categoryId: "transaction",
        categoryName: "Transaction",
        iconType: "transaction",
    },
    {
        categoryId: "performance-development",
        categoryName: "Performance & Development",
        iconType: "performance-development",
    },
    {
        categoryId: "checklist-surveys",
        categoryName: "Checklist & Surveys",
        iconType: "checklist-surveys",
    },
    {
        categoryId: "compensation-benefits",
        categoryName: "Compensation & Benefits",
        iconType: "compensation-benefits",
    },
    {
        categoryId: "payroll",
        categoryName: "Payroll",
        iconType: "payroll",
    },
    {
        categoryId: "tools",
        categoryName: "Tools",
        iconType: "tools",
    },
    {
        categoryId: "license",
        categoryName: "License",
        iconType: "license",
    },
];

//Menu Items
export const ROUTES: RouteInfo[] = [
    // {
    //   path:"/home",
    //   title: "Home",
    //   type: "sub",
    //   icontype: "fa fa-home text-primary",
    //   authentical: "",
    //   isCollapsed:false,
    //   children:[
    //     { path: "profile", title: "Profile", type: "link", authentical:""}
    //   ]
    // }
    {
        path: "/dashboards/",
        title: "Dashboard",
        type: "sub",
        icontype: "fa fa-chart-pie text-primary",
        categoryId: "dashboard",
        authentical: "",
        isCollapsed: true,
        children: [
            { path: "hr", title: "HR", type: "link", authentical: "HR Dashboard" },
            {
                path: "time",
                title: "Time",
                type: "link",
                authentical: "Time Dashboard",
            },
            {
                path: "payroll",
                title: "Payroll",
                type: "link",
                authentical: "Payroll Dashboard",
            },
            {
                path: "security",
                title: "Security",
                type: "link",
                authentical: "Security Dashboard",
            },
            {
                path: "recruitment",
                title: "Recruitment",
                type: "link",
                authentical: "Recruitment Dashboard",
            },
            {
                path: "extra",
                title: "Extra",
                type: "link",
                authentical: "Benefit Dashboard,Expense Dashboard,Loan Dashboard,Workspace Dashboard,Asset Dashboard",
            },
            // { path: "apris", title: "APRIS", type: "link", authentical: "APRIS Dashboard"},
        ],
    },
    {
        path: "/quick-access/",
        title: "Quick Access",
        categoryId: "dashboard",
        type: "sub",
        icontype: "fa fa-chart-pie text-primary",
        authentical: "",
        isCollapsed: true,
        children: [], // for quick access (children di set di fungsi get_valid_menu_final)
    },
    {
        path: "/customer/",
        title: "Customer",
        categoryId: "insight",
        type: "sub",
        icontype: "fas fa-users text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "customer",
                title: "Customer",
                type: "link",
                authentical: "SC_ADMIN",
            },
            {
                path: "fail-provisioning",
                title: "Fail Provisioning",
                type: "link",
                authentical: "SC_ADMIN",
            },
            {
                path: "fail-instance",
                title: "Fail Instance",
                type: "link",
                authentical: "SC_ADMIN",
            },
            {
                path: "server",
                title: "Server",
                type: "link",
                authentical: "SC_ADMIN",
            },
            {
                path: "maintenance",
                title: "Maintenance",
                type: "link",
                authentical: "",
            },
            {
                path: "batch-update",
                title: "Batch Update",
                type: "link",
                authentical: "",
            },
        ],
    },
    {
        path: "/starfeeds/",
        title: "Starfeeds",
        categoryId: "insight",
        type: "sub",
        icontype: "fas fa-lock text-primary",
        authentical: "",
        isCollapsed: true,
        isImage: true,
        imgSrc: "../../../assets/svg/starfeeds.svg",
        children: [
            {
                path: "category",
                title: "Category",
                type: "link",
                authentical: "Starfeeds Setup",
            },
            {
                path: "feeds",
                title: "Feeds",
                type: "link",
                authentical: "Starfeeds Setup",
            },
        ],
    },
    {
        path: "/workforce/",
        title: "Workforce",
        categoryId: "people-structure",
        type: "sub",
        icontype: "fa fa-id-badge text-primary ml-1 mr--1",
        authentical: "",
        isCollapsed: true,
        children: [{ path: "employee", title: "Employee", type: "link", authentical: "" }],
    },
    {
        path: "/my-team/",
        title: "My Team",
        categoryId: "people-structure",
        type: "sub",
        icontype: "fas fa-file-alt text-primary",
        isImage: true,
        imgSrc: "../../../assets/svg/myteam.svg",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "my-teams",
                title: "My Team",
                type: "link",
                authentical: "My Team",
            },
            // { path: "career-evaluation", title: "Career Evaluation", type: "link", authentical: "Career Assessment Evaluator" },
            {
                path: "report-manager",
                title: "Report",
                type: "link",
                authentical: "My Team",
            },
        ],
    },
    {
        path: "/work-structure/",
        title: "Work Structure",
        type: "sub",
        categoryId: "people-structure",
        icontype: "fa fa-sitemap text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "company",
                title: "Company",
                type: "link",
                authentical: "Work Structure",
            },
            {
                path: "location-group",
                title: "Location Group",
                type: "link",
                authentical: "Work Structure",
            },
            {
                path: "location",
                title: "Location",
                type: "link",
                authentical: "Work Structure",
            },
            {
                path: "grade",
                title: "Grade",
                type: "link",
                authentical: "Work Structure",
            },
            {
                path: "job-family",
                title: "Job Family",
                type: "link",
                authentical: "Work Structure",
            },
            {
                path: "job",
                title: "Job",
                type: "link",
                authentical: "Work Structure",
            },
            {
                path: "organization-level",
                title: "Organization Level",
                type: "link",
                authentical: "Work Structure",
            },
            {
                path: "organization",
                title: "Organization",
                type: "link",
                authentical: "Work Structure",
            },
            {
                path: "position-level",
                title: "Position Level",
                type: "link",
                authentical: "Work Structure",
            },
            {
                path: "position",
                title: "Position",
                type: "link",
                authentical: "Work Structure",
            },
        ],
    },
    {
        path: "/recruitment/",
        title: "Setup",
        type: "sub",
        categoryId: "recruitments",
        icontype: "fa fa-user-plus text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "setup/portals",
                title: "Portals",
                type: "link",
                authentical: "Recruitment Setup",
            },
            {
                path: "setup/client",
                title: "Client",
                type: "link",
                authentical: "Recruitment Setup",
            },
            {
                path: "setup/mpp",
                title: "Mpp",
                type: "link",
                authentical: "Recruitment Setup",
            },
            {
                path: "setup/category",
                title: "Category",
                type: "link",
                authentical: "Recruitment Setup",
            },
        ],
    },
    {
        path: "/recruitment/",
        title: "Template",
        type: "sub",
        categoryId: "recruitments",
        icontype: "fa fa-user-plus text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "template/question",
                title: "Question",
                type: "link",
                authentical: "Recruitment Setup",
            },
            {
                path: "template/section",
                title: "Section",
                type: "link",
                authentical: "Recruitment Setup",
            },
            {
                path: "template/template",
                title: "Template",
                type: "link",
                authentical: "Recruitment Setup",
            },
            {
                path: "template/hiring-phase",
                title: "Hiring Phase",
                type: "link",
                authentical: "Recruitment Setup",
            },
            {
                path: "template/hiring-process",
                title: "Hiring Process",
                type: "link",
                authentical: "Recruitment Setup",
            },
        ],
    },
    {
        path: "/recruitment/",
        title: "Vacancy",
        type: "sub",
        categoryId: "recruitments",
        icontype: "fa fa-user-plus text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "vacancy/job-requisition",
                title: "Job Requisition",
                type: "link",
                authentical: "Recruitment Setup",
            },
            {
                path: "vacancy/job-posting",
                title: "Job Posting",
                type: "link",
                authentical: "Recruitment Setup",
            },
        ],
    },
    {
        path: "/recruitment/",
        title: "Applicant Screening",
        type: "sub",
        categoryId: "recruitments",
        icontype: "fa fa-user-plus text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "applicant-screening/applicant",
                title: "Applicant",
                type: "link",
                authentical: "Recruitment Setup",
            },
            {
                path: "applicant-screening/applicant-selection",
                title: "Applicant Selection",
                type: "link",
                authentical: "Recruitment Setup",
            },
        ],
    },
    {
        path: "/time-management/",
        title: "Time",
        categoryId: "time-absence",
        type: "sub",
        icontype: "fas fa-clock text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "holiday",
                title: "Holiday",
                type: "link",
                authentical: "Attendance Setup",
            },
            {
                path: "shift",
                title: "Shift",
                type: "link",
                authentical: "Attendance Setup",
            },
            {
                path: "pattern",
                title: "Pattern",
                type: "link",
                authentical: "Attendance Setup",
            },
            {
                path: "attendance-group",
                title: "Attendance Group",
                type: "link",
                authentical: "Attendance Setup",
            },
        ],
    },
    {
        path: "/absence/",
        title: "Absence",
        categoryId: "time-absence",
        type: "sub",
        icontype: "fas fa-calendar-check text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "eligibility-profiles",
                title: "Eligibility Profiles",
                type: "link",
                authentical: "Absence Setup",
            },
            {
                path: "absence-type",
                title: "Absence Type",
                type: "link",
                authentical: "Absence Setup",
            },
            {
                path: "adjust-balance",
                title: "Adjust Balance",
                type: "link",
                authentical: "Absence Setup",
            },
        ],
    },
    {
        path: "/benefit/",
        title: "Benefit",
        categoryId: "compensation-benefits",
        type: "sub",
        icontype: "fas fa-money-bill-alt text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "eligibility-profiles",
                title: "Eligibility Profiles",
                type: "link",
                authentical: "Benefit Setup",
            },
            {
                path: "benefit-type",
                title: "Benefit Type",
                type: "link",
                authentical: "Benefit Setup",
            },
            {
                path: "adjust-balance",
                title: "Adjust Balance",
                type: "link",
                authentical: "Benefit Setup",
            },
        ],
    },
    {
        path: "/expense/",
        title: "Expense",
        type: "sub",
        categoryId: "compensation-benefits",
        icontype: "fa fa-file-invoice-dollar text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "currencies",
                title: "Currencies",
                type: "link",
                authentical: "Expense Setup",
            },
            {
                path: "exchange-rates",
                title: "Exchange Rates",
                type: "link",
                authentical: "Expense Setup",
            },
            {
                path: "expense-type",
                title: "Expense Type",
                type: "link",
                authentical: "Expense Setup",
            },
            {
                path: "project",
                title: "Project",
                type: "link",
                authentical: "Expense Setup",
            },
        ],
    },
    {
        path: "/business-trip/",
        title: "Business Trip",
        type: "sub",
        categoryId: "compensation-benefits",
        icontype: "fa fa-file-invoice-dollar text-primary",
        authentical: "",
        isCollapsed: true,
        isImage: true,
        imgSrc: "../../../assets/svg/business-trip-icon.svg",
        children: [
            {
                path: "cities",
                title: "Cities",
                type: "link",
                authentical: "Expense Setup",
            },
            {
                path: "categories",
                title: "Categories",
                type: "link",
                authentical: "Expense Setup",
            },
        ],
    },
    {
        path: "/loan/",
        title: "Loan",
        type: "sub",
        categoryId: "compensation-benefits",
        icontype: "fas fa-hand-holding-usd text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "eligibility-profiles",
                title: "Eligibility Profiles",
                type: "link",
                authentical: "Loan",
            },
            {
                path: "loan-type",
                title: "Loan Type",
                type: "link",
                authentical: "Loan",
            },
        ],
    },
    {
        path: "/asset/",
        title: "Asset",
        type: "sub",
        categoryId: "facilities",
        icontype: "fas fa-box-open text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "eligibility-profiles",
                title: "Eligibility Profiles",
                type: "link",
                authentical: "Asset Setup",
            },
            {
                path: "asset-type",
                title: "Asset Type",
                type: "link",
                authentical: "Asset Setup",
            },
        ],
    },
    {
        path: "/workspace/",
        title: "Workspace",
        type: "sub",
        categoryId: "facilities",
        icontype: "fas fa-building text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "eligibility-profiles",
                title: "Eligibility Profiles",
                type: "link",
                authentical: "Workspace Setup",
            },
            {
                path: "workspace-type",
                title: "Workspace Type",
                type: "link",
                authentical: "Workspace Setup",
            },
        ],
    },
    {
        path: "/transaction/",
        title: "Personal",
        type: "sub",
        categoryId: "transaction",
        icontype: "fas fa-file-alt text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "personal/biodata",
                title: "Biodata",
                type: "link",
                authentical: "Employee Information",
            },
            {
                path: "personal/address",
                title: "Address",
                type: "link",
                authentical: "Employee Information",
            },
            {
                path: "personal/family",
                title: "Family",
                type: "link",
                authentical: "Employee Information",
            },
            {
                path: "personal/education",
                title: "Education",
                type: "link",
                authentical: "Employee Information",
            },
            {
                path: "personal/work-experiences",
                title: "Work Experiences",
                type: "link",
                authentical: "Employee Information",
            },
            {
                path: "personal/certification",
                title: "Certification",
                type: "link",
                authentical: "Employee Information",
            },
            {
                path: "personal/document",
                title: "Document",
                type: "link",
                authentical: "Employee Information",
            },
        ],
    },
    {
        path: "/transaction/",
        title: "Time",
        type: "sub",
        categoryId: "transaction",
        icontype: "fas fa-file-alt text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "time/checkinout",
                title: "Check In Out",
                type: "link",
                authentical: "Attendance Setup",
            },
            {
                path: "time/overtime",
                title: "Overtime",
                type: "link",
                authentical: "Attendance Setup",
            },
            {
                path: "time/time-off",
                title: "Time Off",
                type: "link",
                authentical: "Attendance Setup",
            },
            {
                path: "time/shift-change",
                title: "Shift Change",
                type: "link",
                authentical: "Attendance Setup",
            },
            {
                path: "time/absence",
                title: "Absence",
                type: "link",
                authentical: "Absence Setup",
            },
        ],
    },
    {
        path: "/transaction/",
        title: "Talent",
        type: "sub",
        categoryId: "transaction",
        icontype: "fas fa-file-alt text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "talent/goal",
                title: "Goal",
                type: "link",
                authentical: "Goal Setup",
            },
            {
                path: "talent/career-evaluation",
                title: "Career Evaluation",
                type: "link",
                authentical: "Career Assessment Evaluator",
            },
        ],
    },
    {
        path: "/transaction/",
        title: "Extra",
        type: "sub",
        categoryId: "transaction",
        icontype: "fas fa-file-alt text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "extra/benefit",
                title: "Benefit",
                type: "link",
                authentical: "Benefit Setup",
            },
            {
                path: "extra/expense",
                title: "Expense",
                type: "link",
                authentical: "Expense Setup",
            },
            { path: "extra/loan", title: "Loan", type: "link", authentical: "Loan" },
            {
                path: "extra/workspace",
                title: "Workspace",
                type: "link",
                authentical: "Workspace Setup",
            },
            {
                path: "extra/business-trip",
                title: "Business Trip",
                type: "link",
                authentical: "Expense Setup",
            },
            {
                path: "extra/starpoint",
                title: "StarPoint",
                type: "link",
                authentical: "Point Setup",
            },
            {
                path: "extra/talent-matrix",
                title: "Talent Matrix",
                type: "link",
                authentical: "Point Setup",
            },
        ],
    },
    {
        path: "/goal/",
        title: "Goal",
        type: "sub",
        categoryId: "performance-development",
        icontype: "fas fa-star text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "eligibility-profiles",
                title: "Eligibility Profiles",
                type: "link",
                authentical: "Goal Setup",
            },
            {
                path: "goal-library",
                title: "Goal Library",
                type: "link",
                authentical: "Goal Setup",
            },
            {
                path: "goal-plan",
                title: "Goal Plan",
                type: "link",
                authentical: "Goal Setup",
            },
        ],
    },
    {
        path: "/competency/",
        title: "Competency",
        type: "sub",
        categoryId: "performance-development",
        icontype: "fas fa-chart-line text-primary",
        isImage: true,
        imgSrc: "../../../assets/svg/competency-icon.svg",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "rating-model",
                title: "Rating Model",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "competency-category",
                title: "Competency Category",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "competencies",
                title: "Competencies",
                type: "link",
                authentical: "Performance Setup",
            },
        ],
    },
    {
        path: "/performance/",
        title: "Performance",
        type: "sub",
        categoryId: "performance-development",
        icontype: "fas fa-chart-line text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "eligibility-profiles",
                title: "Eligibility Profiles",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "question",
                title: "Question",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "competency-category",
                title: "Competency Category",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "competencies",
                title: "Competencies",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "rating-model",
                title: "Rating Model",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "item-category",
                title: "Item Category",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "item",
                title: "Item",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "section",
                title: "Section",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "template",
                title: "Template",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "manage-evaluation",
                title: "Manage Evaluation",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "calibration",
                title: "Calibration",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "talent-matrix",
                title: "Talent Matrix",
                type: "link",
                authentical: "Performance Setup",
            },
        ],
    },
    {
        path: "/career/",
        title: "Career",
        type: "sub",
        categoryId: "performance-development",
        icontype: "fas fa-file-alt text-primary",
        isImage: true,
        imgSrc: "../../../assets/svg/career-icon.svg",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "question",
                title: "Question",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "item",
                title: "Item",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "template",
                title: "Template",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "assessment-process",
                title: "Assessment Process",
                type: "link",
                authentical: "Performance Setup",
            },
            {
                path: "manage-assessment",
                title: "Manage Assessment",
                type: "link",
                authentical: "Performance Setup",
            },
        ],
    },
    {
        path: "/checklist/",
        title: "Checklist",
        type: "sub",
        categoryId: "checklist-surveys",
        icontype: "fas fa-check-square text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "eligibility-profiles",
                title: "Eligibility Profiles",
                type: "link",
                authentical: "Checklist Setup",
            },
            {
                path: "checklist-template",
                title: "Template",
                type: "link",
                authentical: "Checklist Setup",
            },
        ],
    },
    {
        path: "/questionnaire/",
        title: "Questionnaire",
        type: "sub",
        categoryId: "checklist-surveys",
        icontype: "fas fa-question-circle text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "eligibility-profiles",
                title: "Eligibility Profiles",
                type: "link",
                authentical: "Questionnaire Setup",
            },
            {
                path: "question",
                title: "Question",
                type: "link",
                authentical: "Questionnaire Setup",
            },
            {
                path: "section",
                title: "Section",
                type: "link",
                authentical: "Questionnaire Setup",
            },
            {
                path: "template",
                title: "Template",
                type: "link",
                authentical: "Questionnaire Setup",
            },
        ],
    },
    // {
    //   path: "/learning",
    //   title: "Learning",
    //   type: "sub",
    //   icontype: "fas fa-book text-primary",
    //   authentical: "",
    //   isCollapsed: true,
    //   children: [
    //     { path: "badge", title: "Badge", type: "link", authentical: "Learning Setup" },
    //   ]
    // },
    {
        path: "/voice-of-employee/",
        title: "Voice Of Employee",
        type: "sub",
        categoryId: "checklist-surveys",
        icontype: "fas fa-microphone text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "category",
                title: "Category",
                type: "link",
                authentical: "Voice of Employee Admin",
            },
            {
                path: "incident",
                title: "Incident",
                type: "link",
                authentical: "Voice of Employee Admin",
            },
        ],
    },
    {
        path: "/starpoint/",
        title: "StarPoint",
        type: "sub",
        categoryId: "compensation-benefits",
        icontype: "fas fa-file-alt text-primary",
        authentical: "",
        isCollapsed: true,
        isImage: true,
        imgSrc: "../../../assets/svg/point-icon.svg",
        children: [
            {
                path: "level",
                title: "Level",
                type: "link",
                authentical: "Point Setup",
            },
            {
                path: "catalog",
                title: "Catalog",
                type: "link",
                authentical: "Point Setup",
            },
            {
                path: "manage-point",
                title: "Manage Point",
                type: "link",
                authentical: "Point Setup",
            },
        ],
    },
    {
        path: "/payroll/",
        title: "Setup",
        type: "sub",
        categoryId: "payroll",
        icontype: "fas fa-file-invoice-dollar text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "setup/chart-of-accounts",
                title: "Chart of Accounts",
                type: "link",
                authentical: "Payroll Setup",
            },
            {
                path: "setup/tax-bracket",
                title: "Tax Bracket",
                type: "link",
                authentical: "Payroll Setup",
            },
            {
                path: "setup/payroll-group",
                title: "Payroll Group",
                type: "link",
                authentical: "Payroll Setup",
            },
            {
                path: "setup/element-classification",
                title: "Element Classification",
                type: "link",
                authentical: "Payroll Setup",
            },
            {
                path: "setup/element",
                title: "Element",
                type: "link",
                authentical: "Payroll Setup",
            },
            {
                path: "setup/balance",
                title: "Balance",
                type: "link",
                authentical: "Payroll Setup",
            },
            {
                path: "setup/runtype",
                title: "Run Type",
                type: "link",
                authentical: "Payroll Setup",
            },
        ],
    },
    {
        path: "/payroll/",
        title: "Transaction",
        type: "sub",
        categoryId: "payroll",
        icontype: "fas fa-file-invoice-dollar text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "transaction/process-result",
                title: "Process & Result",
                type: "link",
                authentical: "Payroll Process",
            },
            {
                path: "transaction/costing-payroll",
                title: "Costing Payroll",
                type: "link",
                authentical: "Payroll Process",
            },
            {
                path: "transaction/closing-payroll",
                title: "Closing Payroll",
                type: "link",
                authentical: "Payroll Process",
            },
            {
                path: "transaction/tax-process",
                title: "1721A1 Process",
                type: "link",
                authentical: "Payroll Process",
            },
            {
                path: "transaction/compensation-change",
                title: "Compensation Change",
                type: "link",
                authentical: "Payroll Process",
            },
        ],
    },
    {
        path: "/workflow-approval/",
        title: "Workflow Approval",
        categoryId: "tools",
        type: "sub",
        icontype: "fas fa-tasks text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "eligibility-profiles",
                title: "Eligibility Profiles",
                type: "link",
                authentical: "Workflow Setup",
            },
            {
                path: "approval-group",
                title: "Approval Group",
                type: "link",
                authentical: "Workflow Setup",
            },
            {
                path: "workflow",
                title: "Workflow",
                type: "link",
                authentical: "Workflow Setup",
            },
        ],
    },
    {
        path: "/security/",
        title: "Security",
        type: "sub",
        categoryId: "tools",
        icontype: "fas fa-lock text-primary",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "eligibility-profiles",
                title: "Eligibility Profiles",
                type: "link",
                authentical: "Security Setup",
            },
            {
                path: "roles",
                title: "Roles",
                type: "link",
                authentical: "Security Setup",
            },
            {
                path: "users",
                title: "Users",
                type: "link",
                authentical: "Security Setup",
            },
        ],
    },
    {
        path: "/template/",
        title: "Template",
        type: "sub",
        categoryId: "tools",
        icontype: "fas fa-briefcase text-primary",
        authentical: "",
        isCollapsed: true,
        isImage: true,
        imgSrc: "../../../assets/svg/tools-icon.svg",
        children: [
            {
                path: "greetings-template",
                title: "Greetings Template",
                type: "link",
                authentical: "Greeting Template Setup",
            },
            {
                path: "letter-template",
                title: "Letter Template",
                type: "link",
                authentical: "Letter Setup",
            },
        ],
    },
    {
        path: "/tools/",
        title: "Others",
        type: "sub",
        categoryId: "tools",
        icontype: "fas fa-briefcase text-primary",
        authentical: "",
        isCollapsed: true,
        isImage: true,
        imgSrc: "../../../assets/svg/tools-icon.svg",
        children: [
            {
                path: "approval-delegation",
                title: "Approval Delegation",
                type: "link",
                authentical: "Approval Delegation",
            },
            {
                path: "process-manager",
                title: "Process Manager",
                type: "link",
                authentical: "",
            },
            {
                path: "import-data",
                title: "Import Data",
                type: "link",
                authentical: "",
            },
            {
                path: "mass-update",
                title: "Mass Update",
                type: "link",
                authentical: "Mass Updates",
            },
            {
                path: "global-values",
                title: "Global Values",
                type: "link",
                authentical: "Global Value Setup",
            },
            {
                path: "auto-number",
                title: "Auto Number",
                type: "link",
                authentical: "Global Value Setup",
            },
            {
                path: "news-feed",
                title: "News Feed",
                type: "link",
                authentical: "News Feed Setup",
            },
            {
                path: "quotes",
                title: "Quotes",
                type: "link",
                authentical: "Global Value Setup",
            },
            {
                path: "mobile-menu",
                title: "Mobile Menu",
                type: "link",
                authentical: "Mobile Setup",
            },
        ],
    },

    {
        path: "/ai/",
        title: "Ai",
        type: "sub",
        categoryId: "tools",
        icontype: "fas fa-file-alt text-primary",
        isImage: true,
        imgSrc: "../../../assets/svg/career-icon.svg",
        authentical: "",
        isCollapsed: true,
        children: [
            {
                path: "knowledge",
                title: "Knowledge",
                type: "link",
                authentical: "AI Setup",
            },
        ],
    },

    // {
    //   path: "/membership/",
    //   title: "Membership",
    //   type: "sub",
    //   icontype: "fas fa-briefcase text-primary",
    //   isImage:true,
    //   imgSrc:"../../../assets/svg/membership.svg",
    //   authentical: "",
    //   isCollapsed: true,
    //   children: [
    //     { path: "level-field", title: "Level Field", type: "link", authentical: "Performance Setup"}
    //   ]
    // },
    {
        path: "/license",
        title: "License",
        type: "link",
        categoryId: "license",
        icontype: "fas fa-id-card text-primary",
        authentical: "",
        isCollapsed: true,
    },
];

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
    standalone: true,
    imports: [SharingModule],
})
export class SidebarComponent implements OnInit, OnDestroy {
    public menuItems: any[];
    public menuItems_Final: any[];
    public categoryItems: any[];
    public categoryItems_Final: any[] = [];
    public isCollapsed = true;
    openTopNav: boolean = false;
    selectedCategory: string = "dashboard";

    showSkeletonMenuList: boolean = true;

    emitEmployee = new EventEmitter<boolean>();

    ngUnsubscribe: Subject<any> = new Subject();

    constructor(private router: Router, private injector: Injector, public methodServices: MethodServices) {}

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(void 0);
        this.ngUnsubscribe.complete();
    }

    get_only_valid_menu(obj: any[], path?: any) {
        if (typeof path == "undefined") {
            path = "";
        }

        Object.entries(obj).forEach(([k, v]) => {
            if (k == "path") {
                v = v.includes("/quick-access/") ? "" : v;
                path += "/" + v;
            }

            if (v instanceof Object) {
                this.get_only_valid_menu(v, path);
            }

            if (k == "authentical") {
                path = path.replace(/\/\//g, "/").replace(/\/$/, "");
                let inj = this.injector.get(CanActivateGuardServices);

                let check_valid_path: boolean = inj.check_authentical({
                    tipe_check: "path",
                    path: path,
                });
                if (!check_valid_path) {
                    // ada kemungkinan "module" karena hanya satu path saja
                    let str_arr: any = path.split("/").filter(Boolean);
                    if (str_arr.length == 1) {
                        let check_valid_module: boolean = inj.check_authentical({
                            tipe_check: "module",
                            module: path.replace(/\//g, ""),
                        });
                        if (!check_valid_module) {
                            obj["authentical_Check"] = false;
                        } else {
                            obj["authentical_Check"] = true;
                        }
                    } else {
                        obj["authentical_Check"] = false;
                    }
                } else {
                    obj["authentical_Check"] = true;
                }
            }
        });
        return obj;
    }

    remove_invalid_menu(obj: any[]) {
        // hilangkan authentical_Check yang 'false' dalam array
        Object.entries(obj).forEach(([k, v]) => {
            if (typeof v?.["children"] != "undefined" && Array.isArray(v?.["children"])) {
                let obj_temp: any[] = v?.["children"].filter((row) => typeof row?.["authentical_Check"] != "undefined" && row?.["authentical_Check"] == true);

                v["children"] = obj_temp;

                this.remove_invalid_menu(v["children"]);
            }
        });

        return obj;
    }

    get_valid_menu_final(obj: any[]) {
        // UPDATE key 'authentical_Check' pada menu yang valid (true) dan tidak valid (false)

        let menu_temp: any = this.get_only_valid_menu(JSON.parse(JSON.stringify(obj)));

        let childQuickAccess = [];

        let childTemp: any;

        for (var arr in menu_temp) {
            if (menu_temp[arr]["path"] == "/workforce/" && menu_temp[arr]["authentical_Check"]) {
                childTemp = menu_temp[arr]["children"][0];
                childTemp.parentPath = menu_temp[arr].path;
                childQuickAccess.push(childTemp);
            }
            if (menu_temp[arr]["path"] == "/tools/" && menu_temp[arr]["authentical_Check"]) {
                childTemp = menu_temp[arr]["children"][3];
                childTemp.parentPath = menu_temp[arr].path;
                childQuickAccess.push(childTemp);

                childTemp = menu_temp[arr]["children"][1];
                childTemp.parentPath = menu_temp[arr].path;
                childQuickAccess.push(childTemp);
            }
            if (menu_temp[arr]["path"] == "/payroll/" && menu_temp[arr]["title"] == "Transaction" && menu_temp[arr]["authentical_Check"]) {
                childTemp = menu_temp[arr]["children"][0];
                childTemp.parentPath = menu_temp[arr].path;
                childQuickAccess.push(childTemp);
            }
        }

        childQuickAccess = childQuickAccess.sort((a, b) => {
            if (a["title"].toLowerCase() > b["title"].toLowerCase()) {
                return 1;
            } else {
                return -1;
            }
        });

        menu_temp[1]["children"] = [...childQuickAccess];
        menu_temp[1]["authentical_Check"] = menu_temp[1]["children"].length > 0;

        // filter level pertama dahulu untuk yang authentical_Check nya "true"
        let menu_temp_filter_lvl1 = menu_temp.filter((row) => typeof row?.["authentical_Check"] != "undefined" && row?.["authentical_Check"]);
        let menu_valid_temp: any = this.remove_invalid_menu(JSON.parse(JSON.stringify(menu_temp_filter_lvl1)));

        // filter check if category menu tidak ada child (sub menu) maka kategori tersebut tidak perlu ditampilkan
        let checkIdx = 0;
        while (checkIdx < menu_valid_temp.length) {
            if (menu_valid_temp[checkIdx]["children"].length <= 0) {
                menu_valid_temp.splice(checkIdx, 1);
            } else {
                checkIdx++;
            }
        }

        return menu_valid_temp;
    }

    ngOnInit() {
        this.showSkeletonMenuList = true;
        // indikator jika user/profile sudah dihit api nya (dari navbar)
        let status_userprofile: boolean;

        subject_sidebar_userprofile_status.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            status_userprofile = res;
            if (res) {
                this.showSkeletonMenuList = false;
            }
        });
        // ... end

        this.methodServices.openTopNav.subscribe((res) => {
            this.openTopNav = res;
        });

        this.menuItems = ROUTES.filter((menuItem) => menuItem);

        this.categoryItems = categoriesMenus.filter((category) => category);

        let setInt_GITAUTHTKN = setInterval(() => {
            if (typeof this.methodServices.jwt_role_user != "undefined" && this.methodServices.jwt_role_user == "SUPER_ADMIN") {
                this.menuItems[1]["children"] = [];

                let childTemp = this.menuItems[4]["children"][0];
                childTemp.parentPath = this.menuItems[4].path;
                this.menuItems[1]["children"].push(childTemp);

                this.menuItems_Final = this.menuItems.filter((row) => {
                    if (row?.["path"] == "/workforce/" || row?.["path"] == "/security/" || row?.["path"] == "/license" || row?.["path"] == "/quick-access/") {
                        return true;
                    }
                });

                this.showSkeletonMenuList = false;
                this.mappingWithCategoryMenu();
                clearInterval(setInt_GITAUTHTKN);
            } else {
                if (typeof status_userprofile != "undefined" && status_userprofile != null && status_userprofile) {
                    this.menuItems_Final = JSON.parse(JSON.stringify(this.menuItems));

                    if (typeof localStorage.getItem("GITAUTHTKN") != "undefined" && localStorage.getItem("GITAUTHTKN") != null) {
                        // hasil FINAL menuItems yang akan di looping
                        this.menuItems_Final = this.get_valid_menu_final(JSON.parse(JSON.stringify(this.menuItems)));
                        // ... end hasil FINAL menuItems
                    } else {
                        this.menuItems_Final = [];
                    }

                    if (typeof this.methodServices.jwt_role_user != "undefined" && this.methodServices.jwt_role_user == "SC_ADMIN") {
                        let menuItems_Final_find: any = this.menuItems_Final.find((row) => row?.["path"] == "/customer");
                        if (!menuItems_Final_find) {
                            let menuItems_Final_temp: any = this.menuItems.filter((row) => row?.["path"] == "/customer");
                            this.menuItems_Final.push(...menuItems_Final_temp);
                        }
                    }

                    this.mappingWithCategoryMenu();
                    clearInterval(setInt_GITAUTHTKN);
                }
            }
        });

        this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
            this.isCollapsed = true;
        });
    }

    mappingWithCategoryMenu() {
        for (let data of this.menuItems_Final) {
            let findCategoryMenu = this.categoryItems.findIndex((category) => category.categoryId == data.categoryId);
            let findIdxMapping = this.categoryItems_Final.findIndex((tempCategory) => tempCategory.categoryName == this.categoryItems[findCategoryMenu]["categoryName"]);
            //mapping menu
            if (findIdxMapping != -1) {
                this.categoryItems_Final[findIdxMapping]["menu"].push(data);
            } else {
                let dataTemp = [];
                dataTemp.push(data);
                this.categoryItems_Final.push({
                    categoryId: this.categoryItems[findCategoryMenu]["categoryId"],
                    menu: [...dataTemp],
                });
            }
        }
        this.hideCategoryNotAuthority();
    }

    hideCategoryNotAuthority() {
        let temp = [];
        for (let data of this.categoryItems_Final) {
            let checkDataCategory = this.categoryItems.findIndex((res) => res.categoryId === data.categoryId);
            if (checkDataCategory != -1 && !temp.includes(this.categoryItems[checkDataCategory])) {
                temp.push(this.categoryItems[checkDataCategory]);
            }
        }
        this.categoryItems = temp;
    }

    selectCategory(id: string) {
        this.selectedCategory = id;
    }
}
