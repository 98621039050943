<ng-http-loader
    [filteredUrlPatterns]="['/build-details\\.json', 'ai/knowledge/ask-chatbot']"
    [backdrop]="true"
    [backdropBackgroundColor]="'var(--git-sidebar-bg-primary)'"
    [minDuration]="1000"
    [extraDuration]="500"
    [opacity]=".7"
    [spinner]="spinkit.skWanderingCubes"
    [entryComponent]="httpLoaderImage"
>
</ng-http-loader>
